<template>
    <main class="swcluster-main" id="swcluster-datasandbox">
        <!-- page-header -->
        <div class="page-header header-divider">
            <div class="header-component">
                <nav class="breadcrumb">
                    <ol class="breadcrumb-wrapper">
                        <li class="breadcrumb-item"><router-link :to="{name: 'Main'}">홈</router-link></li>
                        <li v-if="beforeRoute.name !== null" class="breadcrumb-item">
                            <a href="javascript:">{{ beforeRoute.name }}</a>
                        </li>
                        <li class="breadcrumb-item is-active" aria-current="page">로그인</li>
                    </ol>
                </nav>
                <h2 class="page-title">로그인</h2>
            </div>
        </div>
        <!-- page-body -->
        <div class="page-body">
            <!-- page-contents -->
            <div class="page-contents">
                <div class="auth-container auth-component">
                    <div>
                        <div class="auth-forms">
                            <div class="form-row"><input v-model.trim="sign.memberId" type="text" placeholder="아이디 입력" /></div>
                            <div class="form-row">
                                <input v-model.trim="sign.memberPwd" type="password" placeholder="비밀번호 입력" @keyup.enter="signIn" />
                            </div>
                            <div class="form-row row-buttons">
                                <button class="btn-login" @click="signIn"><span class="text">로그인</span></button>
                            </div>
                        </div>
                        <!--                        <div class="auth-links">-->
                        <!--                            <a href="" class="link">회원가입</a>-->
                        <!--                            <a href="" class="link">비밀번호 재발급</a>-->
                        <!--                        </div>-->
                    </div>
                </div>
            </div>
            <!-- //page-contents -->
        </div>
        <!-- //page-body -->
    </main>
</template>

<script>
import {computed, reactive} from 'vue';
import ApiService from '@/assets/js/api.service';
import {isSuccess} from '@/assets/js/common.utils';
import {useRouter} from 'vue-router';
import {useStore} from 'vuex';
import {setAuthTm, setBeforeRoute} from '@/assets/js/modules/auth/module';

export default {
    name: 'SignIn',
    setup: function () {
        const router = useRouter();
        const store = useStore();

        const beforeRoute = computed(() => store.state.auth.beforeRoute);

        const sign = reactive({
            memberId: '',
            memberPwd: '',
        });

        const signIn = async () => {
            try {
                const res = await ApiService.post('/v1/auths/sign-in', sign);
                if (isSuccess(res)) {
                    await setAuthTm(0);
                    if (beforeRoute.value.uri === null) {
                        await router.push({name: 'Main'});
                    } else {
                        const uri = beforeRoute.value.uri;
                        await setBeforeRoute({name: null, uri: null});
                        await router.push(uri);
                    }
                } else {
                    alert('아이디와 비밀번호를 다시한번 확인해주세요.');
                }
            } catch (e) {
                console.error(e);
                alert('오류가 발생했습니다. 잠시후 다시 시도해주세요.');
            }
        };

        return {
            sign,
            beforeRoute,
            signIn,
        };
    },
};
</script>
